import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { startPlan, clearStartPlan } from "../actions/new-first-run";
import { getLoggedInUserDetails } from "../../authentication/actions/auth";

import { continuousVisibilityPlan, automatedCloudOpsPlan } from "../constants";

import styles from "./NewFirstRun.module.scss";
import Day2FeatureCard from "./Day2FeatureCard";

const Day2FeatureSelection = ({
  parentNext,
  isOnlyFeatureSelection = false,
}) => {
  const { t } = useTranslation(["firstRun", "common"]);
  const dispatch = useDispatch();
  const [selectedFeaturePlan, setSelectedFeaturePlan] = useState(null);

  const {
    requestingPlan,
    successfullySubscribedToPlan,
    successfullySavedFeaturePreference,
    failedToSubscribeToPlan,
  } = useSelector(({ newFirstRun }) => {
    return {
      requestingPlan: newFirstRun.requestingPlan,
      successfullySubscribedToPlan: newFirstRun.successfullySubscribedToPlan,
      successfullySavedFeaturePreference:
        newFirstRun.successfullySavedFeaturePreference,
      failedToSubscribeToPlan: newFirstRun.failedToSubscribeToPlan,
    };
  }, shallowEqual);

  useEffect(() => {
    if (
      !failedToSubscribeToPlan &&
      successfullySubscribedToPlan &&
      successfullySavedFeaturePreference
    ) {
      parentNext && parentNext();

      if (!isOnlyFeatureSelection) {
        setTimeout(() => {
          dispatch(getLoggedInUserDetails());
        }, 5000);
      }
    }
  }, [
    dispatch,
    parentNext,
    successfullySubscribedToPlan,
    successfullySavedFeaturePreference,
    failedToSubscribeToPlan,
    isOnlyFeatureSelection,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearStartPlan());
    };
  }, [dispatch]);

  const handeSaveFeaturePlan = (plan) => {
    setSelectedFeaturePlan(plan);
  };

  useEffect(() => {
    if (selectedFeaturePlan) {
      dispatch(startPlan(selectedFeaturePlan.featurePlan));
    }
  }, [dispatch, selectedFeaturePlan]);

  const featurePlans = [continuousVisibilityPlan, automatedCloudOpsPlan];

  return (
    <div className={styles.FeatureSelectionBackDrop}>
      <div className="mc-h2-bold">{t("step.selectFeatures")}</div>
      <div className="mb-4 mc-text-base-regular">
        {t("step.chooseYourFeatures")}
      </div>
      <div className={classnames(styles.selectFeatureCardContainer)}>
        {featurePlans.map((featurePlan) => (
          <Day2FeatureCard
            featurePlan={featurePlan}
            isLoading={requestingPlan}
            selectedFeaturePlan={selectedFeaturePlan}
            handleClick={handeSaveFeaturePlan}
          />
        ))}
      </div>
      <div className="mc-text-sm-regular">
        By proceeding, you agree to the CloudOps Copilot (Beta){" "}
        <a
          href="https://montycloud.com/platform-terms/"
          target="_blank"
          rel="noreferrer"
        >
          Terms and Conditions
        </a>
        .
      </div>
    </div>
  );
};

export default Day2FeatureSelection;
