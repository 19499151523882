import config from "../config";
import i18n from "../config/locale/i18n";
import accountBlueImg from "../images/icons/account_blue.svg";
import accountImg from "../images/icons/account.svg";
import allResourcesBlueImg from "../images/icons/all_resources_blue.svg";
import allResourcesImg from "../images/icons/all_resources.svg";
import automationBlueImg from "../images/icons/automation_blue.svg";
import automationImg from "../images/icons/automation.svg";
import botsBlueImg from "../images/icons/bot_blue.svg";
import botImg from "../images/icons/bot.svg";
import childOrgIconActive from "../images/icons/child-org-active.svg";
import childOrgIcon from "../images/icons/child-org.svg";
import complianceBlueImg from "../images/icons/compliance_blue.svg";
import complianceImg from "../images/icons/compliance.svg";
import connectorsBlueImg from "../images/icons/connectors_blue.svg";
import connectorsImg from "../images/icons/connectors.svg";
import costBlueImg from "../images/icons/cost_blue.svg";
import costImg from "../images/icons/cost.svg";
import optimizationBlueImg from "../images/icons/optimization_blue.svg";
import optimizationImg from "../images/icons/optimization.svg";
import fbpBlueImg from "../images/icons/fbp_blue.svg";
import fbpImg from "../images/icons/fbp.svg";
import federatedAccountAccessBlue from "../images/icons/federated-account-blue.svg";
import federatedAccountAccess from "../images/icons/federated-account.svg";
import homeBlueImg from "../images/icons/home_blue.svg";
import homeImg from "../images/icons/home_grey.svg";
import organizationImg from "../images/icons/parent-org-inactive.svg";
import organizationBlueImg from "../images/icons/parent-org.svg";
import policyBlueIcon from "../images/icons/policy-blue.svg";
import policyIcon from "../images/icons/policy.svg";
import projectsBlueImg from "../images/icons/projects_blue.svg";
import projectsImg from "../images/icons/projects.svg";
import reportsBlueImg from "../images/icons/reports_blue.svg";
import reportsImg from "../images/icons/reports.svg";
import securityBlueImg from "../images/icons/security_blue.svg";
import securityImg from "../images/icons/security.svg";
import serversBlueImg from "../images/icons/servers_blue.svg";
import serversImg from "../images/icons/servers.svg";
import servicesBlueImg from "../images/icons/services_blue.svg";
import servicesImg from "../images/icons/services.svg";
import ssoBlueImg from "../images/icons/sso_blue.svg";
import ssoImg from "../images/icons/sso.svg";
import tagPolicyBlueImg from "../images/icons/tag-policy-blue.svg";
import tagPolicyImg from "../images/icons/tag-policy.svg";
import userBlueImg from "../images/icons/user_blue.svg";
import userImg from "../images/icons/user.svg";
import warBlueImg from "../images/icons/war_blue.svg";
import warImg from "../images/icons/war.svg";
import {
  CUSTOMER_ROLE_TYPE,
  SWITCH_ORGNIZATION,
  CLOUDPROVIDERS,
} from "../utils/app-constants.json";
import {
  CO_PILOT_BASE_PATH,
  HOME_BASE_PATH,
  REPORT_SCHEDULES_PATH,
} from "../utils/app-paths.json";
import { isArrayWithLength } from "./array-methods";
import {
  isAppAccessUserFn,
  isDay2AdminUserFn,
  isDeptAdminUserFn,
  isFreeModeUser,
  isOnlyAdminUserFn,
  isReportsAccessUserFn,
} from "./users";

export const getInitialPage = (accessType) => {
  let initialPageUrl = "/app/home/dashboard";
  const freeUser = isFreeModeUser();
  switch (accessType) {
    case "report-access":
      initialPageUrl = "/app/reports";
      break;
    case "day2-admin-access":
    case "full-access": {
      if (freeUser) {
        initialPageUrl = "/app/home/dashboard";
      }
      break;
    }
    case "app-access":
      initialPageUrl = "/app/projects";
      break;
    case "unassigned":
      initialPageUrl = "/app/projects";
      break;
    case "infrastructure-access":
    case "operation-access":
    default:
      break;
  }
  return initialPageUrl;
};

const appAccessRestrictedPaths = [HOME_BASE_PATH];
const noRoleAccessRestrictedPaths = [HOME_BASE_PATH, CO_PILOT_BASE_PATH];
const reportAccessRestrictedPaths = [REPORT_SCHEDULES_PATH];

export const menuKeys = {
  HOME: "home",
  GENERAL: "general",
  PROJECTS: "projects",
  ORGANIZATIONS: "organizations",
  GENERAL_ORGANIZATIONS: "general_organization",
  INVENTORY: "inventory",
  SERVERS: "servers",
  ALL_RESOURCES: "all-resources",
  SERVICES: "services",
  DAY2_OPS: "day2-ops",
  GOVERNANCE: "governance",
  TAG_POLICIES: "tag-policies",
  ALL_POLICIES: "all-policies",
  CLOUDOPS_DASHBBOARD: "cloudops-dashboard",
  AUTOMATION: "automation",
  COST: "cost",
  OPTIMIZATION: "optimization",
  SECURITY: "security",
  COMPLIANCE: "compliance",
  REPORTS: "reports",
  SETTINGS: "settings",
  ACCOUNTS: "accounts",
  USERS: "users",
  SSO: "sso",
  DAY2_BOTS: "day2bots",
  CONNECTORS: "connectors",
  ASSESSMENTS: "assessments",
  WELL_ARCHITECTED_REVIEW: "well-architected-review",
  FOUNDATIONAL_BEST_PRACTICES: "foundational-best-practices",
  TENANTS: "organizations",
  BILLING_SETUP: "billing-setup",
  MANAGEMENT: "management",
  MANAGEMENT_FOUNDATIONAL_BEST_PRACTICES:
    "management-foundational-best-practices",
  FEDERATED_ACCESS: "federated-access",
};

const generalMenuItem = {
  key: menuKeys.GENERAL,
  name: i18n.t("settings:general"),
  title: i18n.t("settings:general"),
  dataTourKey: "navlink-projects",
  firstTab: ["general"],
  link: "settings/general",
  icon: childOrgIcon,
  activeIcon: childOrgIconActive,
};

const settingsSideMenuItems = [
  {
    name: i18n.t("multiTenancy:myOrganization"),
    key: menuKeys.SETTINGS,
    children: [
      generalMenuItem,
      {
        key: menuKeys.ACCOUNTS,
        name: i18n.t("settings:accounts"),
        dataTourKey: "navlink-accounts",
        firstTab: ["accounts"],
        link: "settings/accounts",
        icon: accountImg,
        activeIcon: accountBlueImg,
      },
      {
        key: menuKeys.USERS,
        name: i18n.t("settings:users"),
        dataTourKey: "navlink-users",
        firstTab: ["users"],
        link: "settings/users",
        icon: userImg,
        activeIcon: userBlueImg,
      },
      {
        key: menuKeys.FEDERATED_ACCESS,
        name: i18n.t("settings:federatedAccess"),
        dataTourKey: "navlink-federated-access",
        firstTab: ["federated-access"],
        link: "settings/federated-access",
        icon: federatedAccountAccess,
        activeIcon: federatedAccountAccessBlue,
      },
      {
        key: menuKeys.SSO,
        name: i18n.t("settings:sso"),
        dataTourKey: "navlink-sso",
        firstTab: ["sso"],
        link: "settings/sso",
        icon: ssoImg,
        activeIcon: ssoBlueImg,
      },

      {
        key: menuKeys.DAY2_BOTS,
        name: i18n.t("settings:day2Bots"),
        dataTourKey: "navlink-day2bots",
        firstTab: ["bots"],
        link: "settings/bots",
        icon: botImg,
        activeIcon: botsBlueImg,
      },

      {
        key: menuKeys.CONNECTORS,
        name: i18n.t("settings:connectors"),
        dataTourKey: "navlink-connectors",
        firstTab: ["connectors"],
        link: "settings/connectors",
        icon: connectorsImg,
        activeIcon: connectorsBlueImg,
      },
    ],
  },
];

const awsSideMenuItems = [
  {
    key: menuKeys.HOME,
    name: "Home",
    dataTourKey: "navlink-home",
    firstTab: ["home"],
    link: "home",
    icon: homeImg,
    activeIcon: homeBlueImg,
    isProFeature: false,
  },
  {
    key: menuKeys.PROJECTS,
    name: "Projects",
    dataTourKey: "navlink-projects",
    firstTab: ["projects", "project", "applications"],
    link: "projects",
    icon: projectsImg,
    activeIcon: projectsBlueImg,
    isProFeature: false,
  },
  {
    name: "Assessments",
    key: menuKeys.ASSESSMENTS,
    children: [
      {
        key: menuKeys.WELL_ARCHITECTED_REVIEW,
        name: "Well-Architected",
        dataTourKey: "navlink-assessments",
        firstTab: ["assessments"],
        link: "assessments",
        isProFeature: true,
        icon: warImg,
        activeIcon: warBlueImg,
      },
      {
        key: menuKeys.FOUNDATIONAL_BEST_PRACTICES,
        name: "Foundational Best Practices",
        dataTourKey: "navlink-" + menuKeys.FOUNDATIONAL_BEST_PRACTICES,
        firstTab: [menuKeys.FOUNDATIONAL_BEST_PRACTICES],
        link: menuKeys.FOUNDATIONAL_BEST_PRACTICES,
        isProFeature: true,
        icon: fbpImg,
        activeIcon: fbpBlueImg,
      },
    ],
  },
  {
    name: "Inventory",
    key: menuKeys.INVENTORY,
    children: [
      {
        key: menuKeys.SERVERS,
        name: "Servers",
        dataTourKey: "navlink-servers",
        firstTab: ["servers"],
        link: "servers",
        icon: serversImg,
        activeIcon: serversBlueImg,
      },
      {
        key: menuKeys.ALL_RESOURCES,
        name: "All Resources",
        dataTourKey: "navlink-all-resources",
        firstTab: ["all-resources"],
        link: "all-resources/inventory",
        icon: allResourcesImg,
        activeIcon: allResourcesBlueImg,
      },
    ],
  },
  {
    name: "Governance",
    key: menuKeys.GOVERNANCE,
    children: [
      {
        key: menuKeys.TAG_POLICIES,
        name: "Tags",
        firstTab: ["governance", "governance/tags"],
        link: "governance/tags",
        icon: tagPolicyImg,
        activeIcon: tagPolicyBlueImg,
      },
      {
        key: menuKeys.ALL_POLICIES,
        name: "Policies",
        firstTab: ["policies", "policies/patch-compliance"],
        link: "policies/patch-compliance",
        icon: policyIcon,
        activeIcon: policyBlueIcon,
      },
      {
        key: menuKeys.SERVICES,
        name: "Service Catalog",
        dataTourKey: "navlink-services",
        firstTab: ["services"],
        link: "services",
        icon: servicesImg,
        activeIcon: servicesBlueImg,
      },
    ],
  },
  {
    name: "DAY2 CloudOps",
    key: menuKeys.DAY2_OPS,
    children: [
      {
        key: menuKeys.COST,
        name: "Cost",
        dataTourKey: "navlink-cost",
        firstTab: ["cost", "billing-groups", "pricing-plans"],
        link: "cost",
        icon: costImg,
        activeIcon: costBlueImg,
      },
      {
        key: menuKeys.OPTIMIZATION,
        name: "Optimization",
        dataTourKey: "navlink-optimization",
        firstTab: ["optimization"],
        link: "optimization",
        icon: optimizationImg,
        activeIcon: optimizationBlueImg,
      },
      {
        key: menuKeys.SECURITY,
        name: "Security",
        dataTourKey: "navlink-security",
        firstTab: ["security"],
        link: "security",
        icon: securityImg,
        activeIcon: securityBlueImg,
      },

      {
        key: menuKeys.COMPLIANCE,
        name: "Compliance",
        dataTourKey: "navlink-compliance",
        firstTab: ["compliance"],
        link: "compliance",
        icon: complianceImg,
        activeIcon: complianceBlueImg,
      },
      {
        key: menuKeys.AUTOMATION,
        name: "Automation",
        dataTourKey: "navlink-automation",
        firstTab: ["automation"],
        link: "automation/tasks/catalog",
        isProFeature: true,
        icon: automationImg,
        activeIcon: automationBlueImg,
      },
    ],
  },
  {
    key: menuKeys.REPORTS,
    name: "Reports",
    dataTourKey: "navlink-reports",
    firstTab: ["reports"],
    link: "reports",
    isProFeature: true,
    icon: reportsImg,
    activeIcon: reportsBlueImg,
  },
];

const azureSideMenuItems = [
  {
    key: menuKeys.HOME,
    name: "Home",
    dataTourKey: "navlink-home",
    firstTab: ["home"],
    link: "home",
    icon: homeImg,
    activeIcon: homeBlueImg,
    isProFeature: false,
  },
  {
    name: "Assessments",
    key: menuKeys.ASSESSMENTS,
    isUpcomingFeature: true,
    children: [
      {
        key: menuKeys.WELL_ARCHITECTED_REVIEW,
        name: "Well-Architected",
        dataTourKey: "navlink-assessments",
        firstTab: ["assessments"],
        link: "assessments",
        isProFeature: true,
        icon: warImg,
        activeIcon: warBlueImg,
        isUpcomingFeature: true,
      },
      {
        key: menuKeys.FOUNDATIONAL_BEST_PRACTICES,
        name: "Foundational Best Practices",
        dataTourKey: "navlink-" + menuKeys.FOUNDATIONAL_BEST_PRACTICES,
        firstTab: [menuKeys.FOUNDATIONAL_BEST_PRACTICES],
        link: menuKeys.FOUNDATIONAL_BEST_PRACTICES,
        isProFeature: true,
        icon: fbpImg,
        activeIcon: fbpBlueImg,
        isUpcomingFeature: true,
      },
    ],
  },
  {
    name: "Inventory",
    key: menuKeys.INVENTORY,
    children: [
      {
        key: menuKeys.ALL_RESOURCES,
        name: "All Resources",
        dataTourKey: "navlink-all-resources",
        firstTab: ["all-resources"],
        link: "all-resources/inventory",
        icon: allResourcesImg,
        activeIcon: allResourcesBlueImg,
      },
    ],
  },
  {
    name: "DAY2 CloudOps",
    key: menuKeys.DAY2_OPS,
    children: [
      {
        key: menuKeys.COST,
        name: "Cost",
        dataTourKey: "navlink-cost",
        firstTab: ["cost", "billing-groups", "pricing-plans"],
        link: "cost",
        icon: costImg,
        activeIcon: costBlueImg,
      },

      {
        key: menuKeys.SECURITY,
        name: "Security",
        dataTourKey: "navlink-security",
        firstTab: ["security"],
        link: "security",
        icon: securityImg,
        activeIcon: securityBlueImg,
        isUpcomingFeature: true,
      },

      {
        key: menuKeys.COMPLIANCE,
        name: "Compliance",
        dataTourKey: "navlink-compliance",
        firstTab: ["compliance"],
        link: "compliance",
        icon: complianceImg,
        activeIcon: complianceBlueImg,
        isUpcomingFeature: true,
      },
      {
        key: menuKeys.AUTOMATION,
        name: "Automation",
        dataTourKey: "navlink-automation",
        firstTab: ["automation"],
        link: "automation/tasks/catalog",
        isProFeature: true,
        icon: automationImg,
        activeIcon: automationBlueImg,
        isUpcomingFeature: true,
      },
    ],
  },
  {
    key: menuKeys.REPORTS,
    name: "Reports",
    dataTourKey: "navlink-reports",
    firstTab: ["reports"],
    link: "reports",
    isProFeature: true,
    icon: reportsImg,
    activeIcon: reportsBlueImg,
  },
];

const managementSideMenuItems = [
  {
    name: "Management",
    key: menuKeys.MANAGEMENT,
    children: [
      {
        key: menuKeys.BILLING_SETUP,
        name: "Billing",
        dataTourKey: "navlink-billing-setup",
        firstTab: ["billing-setup"],
        link: "billing-setup",
        icon: costImg,
        activeIcon: costBlueImg,
        isProFeature: false,
      },
      {
        key: menuKeys.BILLING_SETUP,
        name: "Reports",
        dataTourKey: "navlink-management-reports",
        firstTab: ["management-reports"],
        link: "management-reports",
        icon: reportsImg,
        activeIcon: reportsBlueImg,
        isProFeature: false,
      },
    ],
  },
];

const organisationMenuItem = {
  key: menuKeys.TENANTS,
  name: "Tenants",
  dataTourKey: "navlink-organizations",
  firstTab: ["organizations"],
  link: "organizations",
  isProFeature: false,
  icon: organizationImg,
  activeIcon: organizationBlueImg,
  className: "org-icon-fix",
};

const managementFBPItem = {
  key: menuKeys.MANAGEMENT_FOUNDATIONAL_BEST_PRACTICES,
  name: "Foundational Best Practices",
  dataTourKey: "navlink-" + menuKeys.MANAGEMENT_FOUNDATIONAL_BEST_PRACTICES,
  firstTab: [menuKeys.MANAGEMENT_FOUNDATIONAL_BEST_PRACTICES],
  link: menuKeys.MANAGEMENT_FOUNDATIONAL_BEST_PRACTICES,
  isProFeature: true,
  icon: fbpImg,
  activeIcon: fbpBlueImg,
};

//allowed menus
const day2AdminMenus = [
  menuKeys.MANAGEMENT,
  menuKeys.BILLING_SETUP,
  menuKeys.TENANTS,
  menuKeys.MANAGEMENT_FOUNDATIONAL_BEST_PRACTICES,
];

const adminMenus = [
  menuKeys.HOME,
  menuKeys.PROJECTS,
  menuKeys.ASSESSMENTS,
  menuKeys.INVENTORY,
  menuKeys.GOVERNANCE,
  menuKeys.TAG_POLICIES,
  menuKeys.DAY2_OPS,
  menuKeys.REPORTS,
  menuKeys.FEDERATED_ACCESS,
];

const projectOwnerMenus = [
  menuKeys.HOME,
  menuKeys.PROJECTS,
  menuKeys.INVENTORY,
  menuKeys.SERVICES,
  menuKeys.SECURITY,
  menuKeys.COMPLIANCE,
  menuKeys.AUTOMATION,
  menuKeys.REPORTS,
];

const projectUserMenus = [menuKeys.HOME, menuKeys.PROJECTS, menuKeys.SERVICES];
const noRoleMenus = [menuKeys.PROJECTS, menuKeys.SERVICES];

const reportsAccessMenus = [menuKeys.REPORTS];

const defaultMenus = [menuKeys.HOME, menuKeys.PROJECTS];

const allowedPagesForAll = ["notifications"];

export const getSettingsSideMenuItems = ({ store, featureFlaggedList }) => {
  let menuItems = [...settingsSideMenuItems];
  if (!featureFlaggedList.federatedAccessEnabled) {
    menuItems = menuItems.map((menuItem) => {
      if (menuItem.children) {
        return {
          ...menuItem,
          children: menuItem.children.filter(
            (child) => child.key !== menuKeys.FEDERATED_ACCESS
          ),
        };
      }
      return menuItem;
    });
  }

  return menuItems;
};

const getItemsBasedOnRole = (allowedKeys, cloudProviderType) => {
  let menuItems = [];
  let completeSideMenutItems =
    cloudProviderType === CLOUDPROVIDERS.AWS
      ? [...awsSideMenuItems]
      : [...azureSideMenuItems];
  if (isDay2AdminUserFn()) {
    completeSideMenutItems = [
      ...managementSideMenuItems,
      organisationMenuItem,
      ...(config.demoApp ? [managementFBPItem] : []),
      ...completeSideMenutItems,
    ];
  }
  if (isArrayWithLength(completeSideMenutItems)) {
    completeSideMenutItems.forEach((menuItem) => {
      if (allowedKeys.includes(menuItem.key)) {
        menuItems.push(menuItem);
      } else {
        const childMenu =
          isArrayWithLength(menuItem.children) &&
          menuItem.children.filter((child) => allowedKeys.includes(child.key));
        if (childMenu && isArrayWithLength(childMenu)) {
          let newMenuItem = { ...menuItem };
          newMenuItem.children = childMenu;
          menuItems.push(newMenuItem);
        }
      }
    });
  }
  return menuItems;
};

const removeFeatureFlaggedItems = ({ filteredItems, featureFlaggedList }) => {
  const { fbpEnabled } = featureFlaggedList;
  //enhance this to a switch logic when there are more feature flagged items
  return filteredItems.map((menuNav) => {
    if (isArrayWithLength(menuNav.children)) {
      return {
        ...menuNav,
        children: menuNav.children.filter((child) => {
          const fbpEnableCondition =
            !fbpEnabled && child.key === menuKeys.FOUNDATIONAL_BEST_PRACTICES;
          if (fbpEnableCondition) {
            return false;
          }
          return true;
        }),
      };
    }
    return menuNav;
  });
};

export const getSideMenuItems = ({
  accessType,
  featureFlaggedList,
  cloudProviderType,
}) => {
  const menuItems = (
    cloudProviderType === CLOUDPROVIDERS.AWS
      ? awsSideMenuItems
      : azureSideMenuItems
  ).map((item) => ({ ...item }));

  let filteredItems = [...menuItems];
  switch (accessType) {
    case "full-access":
    case "day2-admin-access":
      filteredItems = getItemsBasedOnRole(adminMenus, cloudProviderType);
      break;
    case "department-access":
      filteredItems = getItemsBasedOnRole(projectOwnerMenus, cloudProviderType);
      break;
    case "app-access":
    case "unassigned":
      filteredItems = getItemsBasedOnRole(noRoleMenus, cloudProviderType);
      break;
    case "report-access":
      filteredItems = getItemsBasedOnRole(
        reportsAccessMenus,
        cloudProviderType
      );
      break;
    default:
      filteredItems = getItemsBasedOnRole(defaultMenus, cloudProviderType);
      break;
  }

  filteredItems = removeFeatureFlaggedItems({
    filteredItems,
    featureFlaggedList,
  });

  return filteredItems;
};

export const getManagementSideMenuItems = ({ featureFlaggedList }) => {
  let updatedManagementSideMenuItems = [...managementSideMenuItems];
  const { MTEnabled } = featureFlaggedList;

  if (isDay2AdminUserFn() && MTEnabled) {
    updatedManagementSideMenuItems = managementSideMenuItems.map((item) => {
      if (item.key === menuKeys.MANAGEMENT) {
        const children = [{ ...organisationMenuItem }, ...item.children];
        if (config.demoApp) {
          // adding FBP menu item before the reports (last menu item)
          const managementFBPMenuPosition = children?.length - 1;
          children?.splice(managementFBPMenuPosition, 0, {
            ...managementFBPItem,
          });
        }
        return {
          ...item,
          children,
        };
      }
      return item;
    });
  }
  return updatedManagementSideMenuItems;
};

const isAllowedRoute = (menuitems, location) => {
  let hasLocation = false;
  hasLocation = allowedPagesForAll.includes(location);
  if (!hasLocation) {
    menuitems.forEach((menuItem) => {
      if (menuItem.firstTab && menuItem.firstTab.includes(location)) {
        hasLocation = true;
      } else if (isArrayWithLength(menuItem.children)) {
        menuItem.children.forEach((childMenu) => {
          if (childMenu.firstTab.includes(location)) {
            hasLocation = true;
          }
        });
      }
    });
  }

  return hasLocation;
};

export const isAllowedRouteForUser = (location, cloudProviderType) => {
  const url = location.pathname.split("/")[2];
  let items = (
    cloudProviderType === CLOUDPROVIDERS.AWS
      ? awsSideMenuItems
      : azureSideMenuItems
  ).map((item) => ({ ...item }));

  if (isDay2AdminUserFn()) {
    items = getItemsBasedOnRole(
      [...day2AdminMenus, ...adminMenus],
      cloudProviderType
    );
  } else if (isOnlyAdminUserFn()) {
    items = getItemsBasedOnRole(adminMenus, cloudProviderType);
  } else if (isDeptAdminUserFn()) {
    items = getItemsBasedOnRole(projectOwnerMenus, cloudProviderType);
  } else if (isAppAccessUserFn()) {
    items = getItemsBasedOnRole(projectUserMenus, cloudProviderType);
  } else if (localStorage.getItem(CUSTOMER_ROLE_TYPE) === "unassigned") {
    items = getItemsBasedOnRole(noRoleMenus, cloudProviderType);
  } else if (isReportsAccessUserFn()) {
    items = getItemsBasedOnRole(reportsAccessMenus, cloudProviderType);
  }
  let isAllowed = isAllowedRoute(items, url);

  //adding this because isAllowedRoute only verifies menu and sub-menu items and not if there is a single menu with top nav options
  if (isAppAccessUserFn()) {
    isAllowed = appAccessRestrictedPaths.includes(location.pathname)
      ? false
      : isAllowed;
  } else if (localStorage.getItem(CUSTOMER_ROLE_TYPE) === "unassigned") {
    isAllowed = noRoleAccessRestrictedPaths.includes(location.pathname)
      ? false
      : isAllowed;
  } else if (isReportsAccessUserFn()) {
    isAllowed = reportAccessRestrictedPaths.includes(location.pathname)
      ? false
      : isAllowed;
  }

  if (url === SWITCH_ORGNIZATION) {
    isAllowed = true;
  }

  return isAllowed;
};

export const isManagementRoute = (location, featureFlaggedList) => {
  const url = location.pathname.split("/")[2];

  const menuItems = getManagementSideMenuItems({ featureFlaggedList });
  let isMangement = false;

  menuItems.forEach((menuItem) => {
    if (menuItem.firstTab && menuItem.firstTab.includes(url)) {
      isMangement = true;
    } else if (isArrayWithLength(menuItem.children)) {
      menuItem.children.forEach((childMenu) => {
        if (childMenu.firstTab.includes(url)) {
          isMangement = true;
        }
      });
    }
  });

  return isMangement;
};
