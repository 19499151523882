import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import ensignupForm from "./en-US/signup-form.json";
import ensignupLayout from "./en-US/signup-layout.json";
import ensignupLayoutServerManagement from "./en-US/signup-layout-server-management.json";
import enloginForm from "./en-US/login-form.json";
import enaddAccount from "./en-US/add-account.json";
import * as englobalConfiguration from "./en-US/global-configuration.json";
import enec2InstanceList from "./en-US/ec2-instance-list.json";
import serverGroup from "./en-US/server-group.json";
import * as enpatchPoliciesList from "./en-US/patch-policies-list.json";
import enamiList from "./en-US/ami-list.json";
import * as enshareAmiScreen from "./en-US/share-ami-screen.json";
import * as encopyAmiScreen from "./en-US/copy-ami-screen.json";
import * as endeleteAmiScreen from "./en-US/delete-ami-screen.json";
import * as enbakeAmiScreen from "./en-US/bake-ami-screen.json";
import encommon from "./en-US/common.json";
import * as enec2TaskScreen from "./en-US/ec2-task.json";
import * as enJobsScreen from "./en-US/jobs.json";
import enScheduledJobScreen from "./en-US/job-schedule.json";
import enPatchGroupScreen from "./en-US/patch-group.json";
import enPatchPolicy from "./en-US/patch-policy.json";
import * as enDeploymentScreen from "./en-US/patch-deplyment-screen.json";
import enUserAndRoleScreen from "./en-US/usersandroles-screen.json";
import * as enMenu from "./en-US/menu.json";
import * as enDashboard from "./en-US/dashboard.json";
import enSnapshot from "./en-US/snapshot-screen.json";
import enForgotPassword from "./en-US/forgotpassword.json";
import * as enAccountOnboard from "./en-US/account-onboard.json";
import enIntegrations from "./en-US/integrations.json";
import enVolumes from "./en-US/volume-screen.json";
import enTasks from "./en-US/tasks.json";
import enMonitoring from "./en-US/monitoring.json";
import enTopCards from "./en-US/topCards.json";
import enResources from "./en-US/resources.json";
import navigationTours from "./en-US/navigation-tour.json";
import enBlueprints from "./en-US/blueprints.json";
import enClassification from "./en-US/classification.json";
import enApplications from "./en-US/applications.json";
import enReports from "./en-US/reports.json";
import enDepartments from "./en-US/departments.json";
import enfeedback from "./en-US/feedback.json";
import enUpgradeNow from "./en-US/upgrade-now.json";
import enPayment from "./en-US/payment.json";
import enDesiredStatePolicy from "./en-US/desired-state-policy.json";
import enImages from "./en-US/images.json";
import enSSO from "./en-US/single-sign-on.json";
import enActivateOnprem from "./en-US/activate-onprem.json";
import enCloudWatch from "./en-US/cloudWatch.json";
import enRules from "./en-US/rules.json";
import enBots from "./en-US/bots.json";
import enStorage from "./en-US/storage.json";
import enCost from "./en-US/cost.json";
import enFirstRun from "./en-US/first-run.json";
import enVerifyEmail from "./en-US/verify-email.json";
import enS3 from "./en-US/s3.json";
import enMFA from "./en-US/mfa.json";
import enWorkspace from "./en-US/workspace.json";
import enNotebookInstance from "./en-US/notebookInstance.json";
import enProject from "./en-US/project.json";
import enSettings from "./en-US/settings.json";
import enElasticIp from "./en-US/elastic-ip.json";
import enResourceColumns from "./en-US/resource-columns.json";
import enAssessments from "./en-US/assessments.json";
import enAssessmentsInsights from "./en-US/assessmentinsights.json";
import enMultiTenancy from "./en-US/multi-tenancy.json";
import enMapProjects from "./en-US/map-projects.json";
import enFoundationalBestPractices from "./en-US/foundationalBestPractices.json";
import vpc from "./en-US/vpc.json";
import terraform from "./en-US/terraform.json";
import enAWSAccounts from "./en-US/awsAccounts.json";
import tenantCategory from "./en-US/tenant-category.json";
import healthEvents from "./en-US/healthEvents.json";
import federatedAccess from "./en-US/federatedAccess.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        addAccount: enaddAccount,
        accountOnboard: enAccountOnboard,
        activateOnprem: enActivateOnprem,
        amiList: enamiList,
        applications: enApplications,
        assessments: enAssessments,
        assessmentinsights: enAssessmentsInsights,
        bakeAmiScreen: enbakeAmiScreen,
        blueprints: enBlueprints,
        bots: enBots,
        classification: enClassification,
        cloudWatch: enCloudWatch,
        cost: enCost,
        common: encommon,
        federatedAccess: federatedAccess,
        copyAmiScreen: encopyAmiScreen,
        dashboard: enDashboard,
        deleteAmiScreen: endeleteAmiScreen,
        DeploymentScreen: enDeploymentScreen,
        departments: enDepartments,
        desiredStatePolicy: enDesiredStatePolicy,
        elasticIP: enElasticIp,
        ec2InstanceList: enec2InstanceList,
        ec2TaskScreen: enec2TaskScreen,
        ec2Common: {},
        forgotPassword: enForgotPassword,
        feedback: enfeedback,
        firstRun: enFirstRun,
        globalConfiguration: englobalConfiguration,
        jobScreen: enJobsScreen,
        loginForm: enloginForm,
        integrations: enIntegrations,
        images: enImages,
        menu: enMenu,
        mfa: enMFA,
        multiTenancy: enMultiTenancy,
        monitoring: enMonitoring,
        mapProjects: enMapProjects,
        notebookInstance: enNotebookInstance,
        patchGroupScreen: enPatchGroupScreen,
        patchPolicy: enPatchPolicy,
        patchPoliciesList: enpatchPoliciesList,
        project: enProject,
        payment: enPayment,
        resourceColumns: enResourceColumns,
        rules: enRules,
        resources: enResources,
        reports: enReports,
        signupForm: ensignupForm,
        signupLayout: ensignupLayout,
        signupLayoutServerManagement: ensignupLayoutServerManagement,
        serverGroup: serverGroup,
        shareAmiScreen: enshareAmiScreen,
        snapshot: enSnapshot,
        scheduledJobScreen: enScheduledJobScreen,
        sso: enSSO,
        s3: enS3,
        settings: enSettings,
        storage: enStorage,
        tasks: enTasks,
        terraform,
        topCard: enTopCards,
        tours: navigationTours,
        userAndRoleScreen: enUserAndRoleScreen,
        upgrade: enUpgradeNow,
        verifyEmail: enVerifyEmail,
        volumes: enVolumes,
        vpc,
        workspace: enWorkspace,
        foundationalBestPractices: enFoundationalBestPractices,
        awsAccounts: enAWSAccounts,
        tenantCategory,
        healthEvents,
      },
      el: {},
    },
    lng: "en",
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ",",
    },

    react: {
      wait: true,
    },
  });

export default i18n;
