import classnames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Col, Input, Row } from "reactstrap";
import ExternalLink from "../../../components/ExternalLink";
import Label from "../../../components/Label";
import MCButton from "../../../components/MCButton";
import ToggleSwitch from "../../../components/ToggleSwitch/ToggleSwitch";
import config from "../../../config";
import { DEFAULT_REGION } from "../../../utils/app-constants.json";
import { partnershipLink, permissionsLink } from "../../../utils/app-links";
import { isArrayWithLength } from "../../../utils/array-methods";
import {
  saveCustomerAccountName,
  setAccountName,
} from "../../account/actions/account";
import { getAccounts } from "../../account/actions/accounts";
import ConfigureAWSAccount from "../../account/components/account/ConfigureAWSAccount/ConfigureAWSAccount";
import { getLoggedInUserDetails } from "../../authentication/actions/auth";
import {
  configureRegions,
  getAllOrganizations,
  saveDepartment,
} from "../../first-run/actions/firstRun";
import { getUserData } from "../../user-data/actions/user-data";
import styles from "./NewFirstRun.module.scss";
import ConnectAccountInfo from "../../account/components/account/ConfigureAWSAccount/ConnectAccountInfo";
import { configureAWSAccountStates } from "../../account/utils/account-constants";

const defaultName = "Default";

const ConnectAccount = ({ parentNext, frePreviousStep }) => {
  const isDemoApp = config.demoApp;
  const dispatch = useDispatch();
  const { t } = useTranslation(["firstRun", "common"]);
  const defaultDescription = t("firstRun:defaultDescription");

  const {
    savedOrg,
    savedDept,
    customerAccountId,
    accountName,
    accountNumber,
    selectedRegions,
    selectedAccountId,
    customerAccountNameSaved,
    customerAccountStatus,
    filteredUserData,
    organizationsLoaded,
    isSavingDept,
    selectedAccountType,
    configureAWSAccountState,
  } = useSelector(({ firstRun, addAccount, globalConfig, auth }) => {
    return {
      savedOrg: firstRun.savedOrg,
      savedDept: firstRun.savedDept,
      customerAccountId: addAccount.customerAccountId,
      accountName: addAccount.accountName,
      accountNumber: addAccount.accountNumber,
      selectedRegions: addAccount.selectedRegions,
      selectedAccountId: addAccount.selectedAccountId,
      customerAccountNameSaved: addAccount.customerAccountNameSaved,
      customerAccountStatus: addAccount.customerAccountStatus,
      organizationsLoaded: globalConfig.organizationsLoaded,
      filteredUserData: globalConfig.filteredUserData,
      isSavingDept: firstRun.isSavingDept,
      companyName: auth.companyName,
      selectedAccountType: firstRun.selectedAccountType,
      configureAWSAccountState: addAccount.configureAWSAccountState,
    };
  }, shallowEqual);

  const [deptName, setDeptName] = useState(defaultName);
  const [deptDescription, setDeptDescription] = useState(defaultDescription);
  const [editAccName, setEditAccName] = useState(false);
  const [isSavingAccount, setIsSavingAccount] = useState(false);
  const [showConfigureAccount, setshowConfigureAccount] = useState(true);
  const [isSaveBtnClicked, setIsSaveBtnClicked] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [showDeptScreen, setShowDeptScreen] = useState(false);
  const [editingDepartment, setEditingDepartment] = useState(false);
  const [stateAccountName, setStateAccountName] = useState(
    accountName || defaultName
  );
  const [showDepartment, setShowDepartment] = useState(false);

  const isConfigurePermissionsStep = useMemo(
    () =>
      showConfigureAccount &&
      configureAWSAccountState ===
        configureAWSAccountStates.SHOW_CONFIGURE_PERMISSIONS
        ? true
        : false,
    [configureAWSAccountState, showConfigureAccount]
  );

  useEffect(() => {
    dispatch(setAccountName(defaultName));
  }, [dispatch]);

  const toggleShowDeptScreen = () => {
    setShowDepartment(!showDepartment);
    setEditingDepartment(false);
  };

  const handleCreateProject = () => {
    dispatch(
      saveDepartment({
        Name: deptName,
        FRE: true,
        Description: deptDescription,
        Accounts: [accountNumber],
      })
    );
  };

  useEffect(() => {
    if (firstRender && !isDemoApp) {
      setTimeout(() => {
        setFirstRender(false);
      }, 1000);
    }
  }, [firstRender, isDemoApp]);

  useEffect(() => {
    if (!organizationsLoaded) {
      dispatch(getAllOrganizations());
    }
  }, [dispatch, organizationsLoaded]);

  const _organizations = filteredUserData && filteredUserData.Organizations;
  const orgExists = isArrayWithLength(_organizations) || savedOrg;

  const goToConfigureAccess = () => {
    setshowConfigureAccount(true);
  };

  useEffect(() => {
    if (savedOrg && isSaveBtnClicked) {
      dispatch(getAllOrganizations());
    }
  }, [dispatch, isSaveBtnClicked, savedOrg]);

  useEffect(() => {
    if (savedDept && isSaveBtnClicked && !showDeptScreen) {
      goToConfigureAccess();
    }
  }, [isSaveBtnClicked, savedDept, showDeptScreen]);

  useEffect(() => {
    if (savedDept && showDeptScreen) {
      setEditingDepartment(false);
      parentNext();
    }
  }, [parentNext, savedDept, showDeptScreen]);

  useEffect(() => {
    if (customerAccountNameSaved) {
      setIsSavingAccount(false);
      setshowConfigureAccount(false);
      setShowDeptScreen(true);
      setTimeout(() => {
        dispatch(getAllOrganizations());
        dispatch(getAccounts());
        dispatch(getLoggedInUserDetails());
        dispatch(getUserData());
      }, 1000);
    }
  }, [customerAccountNameSaved, dispatch]);

  const createDAY2Context = () => {
    setIsSaveBtnClicked(true);
    goToConfigureAccess();
  };

  const selectedRegionsList = isArrayWithLength(selectedRegions)
    ? selectedRegions.map((reg) => {
        if (reg.regionCode === DEFAULT_REGION) {
          reg.isDisabled = true;
        }
        return reg;
      })
    : [];

  const regionList = isArrayWithLength(selectedRegionsList)
    ? selectedRegionsList
        .filter((reg) => reg.isSelected)
        .map((ele) => ele.regionCode)
    : [];

  const saveAccounts = () => {
    setIsSavingAccount(true);
    const params = {
      AccountName: accountName,
    };
    dispatch(saveCustomerAccountName(customerAccountId, params, true));
    dispatch(configureRegions(selectedAccountId, regionList));
  };

  const saveAccount = () => {
    saveAccounts();
  };

  const disableSaveContinue = () => {
    return !accountName || !isArrayWithLength(regionList) || isSavingAccount;
  };

  const accountConnected =
    customerAccountStatus && customerAccountStatus === "CONNECTED";

  const showFullScreen = accountConnected && showConfigureAccount;

  const renderEditText = ({
    label,
    editMode,
    value,
    onEdit,
    onSave,
    onCancel,
    onChange,
    isSaving,
  }) => {
    return (
      <div className="d-flex align-items-center">
        {label}
        {editMode || isSaving ? (
          <>
            <Input
              className={classnames("form-control", styles.editTextBox)}
              id="accNameText"
              type="text"
              value={value}
              onChange={onChange}
              disabled={isSaving}
            />
            {isSaving ? (
              <i className="fa fa-spinner fa-spin ml-2" />
            ) : (
              <>
                <i
                  className={classnames(
                    "fa fa-check ml-2 bg-success",
                    styles.saveCancelIcons
                  )}
                  onClick={onSave}
                />
                <i
                  className={classnames(
                    "fa fa-times ml-2 bg-danger",
                    styles.saveCancelIcons
                  )}
                  onClick={onCancel}
                />
              </>
            )}
          </>
        ) : (
          <span className="ml-2">
            <strong>{value} </strong>
            <i className="fa fa-pencil ml-2 cursor-pointer" onClick={onEdit} />
          </span>
        )}
      </div>
    );
  };

  return (
    <div
      className={classnames(
        styles.welcomeDivLarge,
        styles.ContentBackground,
        styles.ConnectAccountContainer,
        showFullScreen && styles.LargeContainer
      )}
    >
      <Row className="px-4">
        {isConfigurePermissionsStep && (
          <Row className="px-4 m-0">
            <ConnectAccountInfo
              firstRunView={true}
              selectedAccountType={selectedAccountType}
            />
          </Row>
        )}
        <Row className="m-0">
          {showFullScreen ? null : (
            <Col lg={7} className="text-center">
              <div className="text-center">
                <img
                  loading="lazy"
                  src={"/images/dashboard/securely-connect-account.png"}
                  alt="day2"
                  className={classnames(
                    isConfigurePermissionsStep
                      ? styles.connectAccountImageFRE
                      : styles.ConnectAccountImage
                  )}
                />
              </div>

              <Row className={styles.LinksMargin}>
                <Col xl={6} className="p-0">
                  <ExternalLink
                    text={t("firstRun:permissionsDAY2need")}
                    size="medium"
                    externalLink={permissionsLink}
                  />
                </Col>
                <Col xl={6} className="p-0">
                  <ExternalLink
                    text={t("readMoreAboutPartnership")}
                    size="medium"
                    externalLink={partnershipLink}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col lg={showFullScreen ? 12 : 5}>
            {showFullScreen ? (
              <>
                <h2 className={classnames(styles.WelcomeText, "mt-0")}>
                  {t("yourAccount")}&nbsp;
                  <span className="orange-text ml-1 mr-2">{accountNumber}</span>
                  &nbsp;{t("successfullyConnected")}
                </h2>

                <Row className={classnames(styles.day2ContextDiv, "mt-4 mb-3")}>
                  <Col xl={4}>
                    {renderEditText({
                      label: `${t("common:accountNameLabel")}:`,
                      editMode: editAccName,
                      value: stateAccountName,
                      onEdit: () => setEditAccName(true),
                      onSave: () => {
                        setEditAccName(false);
                        dispatch(setAccountName(stateAccountName));
                      },
                      onCancel: () => {
                        setEditAccName(false);
                        setStateAccountName(accountName);
                      },
                      onChange: (e) => setStateAccountName(e.target.value),
                    })}
                  </Col>
                </Row>
              </>
            ) : (
              <div
                className={classnames(
                 "mc-text-lg-bold",
                  styles.relative,
                  "orange-text"
                )}
              >
                {showDeptScreen ? t("departmentHeading") : null}
              </div>
            )}
            {showDeptScreen && (
              <div className="d-flex justify-content-between align-items-center">
                <div className="mc-text-base-regular">{t("addAccount:addAccountProject")}</div>
                <div>
                  <ToggleSwitch
                    checked={showDepartment}
                    id="select-show-budget"
                    onChange={() => toggleShowDeptScreen()}
                    disabledText="No"
                    enabledText="Yes"
                  />
                </div>
              </div>
            )}

            {!showConfigureAccount && (
              <div
                className={classnames(
                  showDeptScreen ? styles.MCHeading3 : styles.MCHeading2,
                  styles.Bold600,
                  "mt-3",
                  styles.WordLetterSpacing
                )}
              >
                {showDepartment ? (
                  <Trans
                    i18nKey="firstRun:departmentSubHeading"
                    values={{ account: accountName }}
                    components={[<strong className="orange-text"></strong>]}
                  />
                ) : null}
              </div>
            )}
            {showDepartment && (
              <>
                <div className={styles.DeptSection}>
                  <div className={styles.SectionHeader}>
                    <span className="ml-2">
                      {isSavingDept ? (
                        <i className="fa fa-spinner fa-spin ml-2" />
                      ) : (
                        !editingDepartment && (
                          <i
                            className="fa fa-pencil ml-2 cursor-pointer"
                            onClick={() => setEditingDepartment(true)}
                          />
                        )
                      )}
                    </span>
                  </div>
                  <div className={styles.SectionContent}>
                    <Row>
                      <Col xl={12}>
                        <Label htmlFor="dept-name" required>
                          <span className={styles.ContentHeading} required>
                            {t("common:nameLabel")}
                          </span>
                        </Label>
                        <Input
                          id="dept-name"
                          className={classnames(
                            "form-control",
                            styles.editDeptTextBox
                          )}
                          type="text"
                          value={deptName}
                          onChange={(e) => setDeptName(e.target.value)}
                          disabled={!editingDepartment || isSavingDept}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className={styles.SectionContent}>
                    <Row>
                      <Col xl={12}>
                        <Label htmlFor="dept-description">
                          <span className={styles.ContentHeading}>
                            {t("common:descriptionLabel")}
                          </span>
                        </Label>
                        <Input
                          id="dept-description"
                          className={classnames(
                            "form-control",
                            styles.editDeptTextBox
                          )}
                          type="textarea"
                          value={deptDescription}
                          onChange={(e) => setDeptDescription(e.target.value)}
                          disabled={!editingDepartment || isSavingDept}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </>
            )}

            <div>
              {showConfigureAccount && (
                <>
                  <div>
                    <ConfigureAWSAccount
                      selectedRegions={selectedRegionsList}
                      firstRunView={true}
                      createDAY2Context={createDAY2Context}
                      orgExists={orgExists}
                      frePreviousStep={frePreviousStep}
                    />
                    {accountConnected && (
                      <MCButton
                        size="lg"
                        disabled={disableSaveContinue()}
                        text={
                          isSavingAccount
                            ? `${t("common:configuring")}...`
                            : t("common:next")
                        }
                        color="mc-blue"
                        onClick={saveAccount}
                        className={classnames(styles.robotoFont, "mt-4")}
                      />
                    )}
                  </div>
                </>
              )}

              {!showConfigureAccount && (
                <div className={styles.ButtonPosition}>
                  {showDeptScreen && (
                    <>
                      <div
                        className={classnames(
                          "mb-3",
                          styles.MCHeading4,
                          "mc-text-base-regular"
                        )}
                      >
                        {t("departmentDescription")}
                      </div>
                      {editingDepartment && (
                        <MCButton
                          size="lg"
                          disabled={isSavingDept}
                          text={t("common:cancel")}
                          color="default"
                          onClick={() => {
                            setDeptName(defaultName);
                            setDeptDescription(defaultDescription);
                            setEditingDepartment(false);
                          }}
                          className={classnames(styles.robotoFont, "mr-2")}
                        />
                      )}

                      <MCButton
                        size="lg"
                        disabled={
                          isSavingDept || (deptName && deptName.length < 3)
                        }
                        text={
                          editingDepartment
                            ? t("common:saveAndContinue")
                            : t("common:next")
                        }
                        color="mc-blue"
                        onClick={
                          showDepartment ? handleCreateProject : parentNext
                        }
                        className={classnames(styles.robotoFont)}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default ConnectAccount;
