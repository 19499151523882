import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../config/customBaseQuery";
import {
  CONFIGURATION_ISSUES,
  CONFIGURED_ACCOUNTS,
  CUSTOMER_ACCOUNT,
  FBP_HEALTH_POLICY_DETAILS,
  FBP_MANAGEMENT_ACCOUNTS,
  POLICY_BASE_URL,
} from "../../../config/service";
import { getApiService } from "../../../helpers/apiService";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiServiceHelper";

export const foundationalBestPracticeApi = createApi({
  reducerPath: "foundationalBestPracticeApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getFoundationalBestPractices: builder.query({
      query: () => {
        return {
          url: `${CUSTOMER_ACCOUNT}${FBP_MANAGEMENT_ACCOUNTS}`,
          method: "GET",
        };
      },
    }),
    getHealthPolicyDetails: builder.query({
      query: () => {
        return {
          url: `${FBP_HEALTH_POLICY_DETAILS}`,
          method: "GET",
        };
      },
    }),
    removeHealthPolicyDetails: builder.mutation({
      query: ({ policyId }) => {
        return {
          url: `${FBP_HEALTH_POLICY_DETAILS}/${policyId}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetFoundationalBestPracticesQuery,
  useGetHealthPolicyDetailsQuery,
  useRemoveHealthPolicyDetailsMutation,
} = foundationalBestPracticeApi;

export const fetchConfiguredAccounts = ({
  policyId,
  limit = 20,
  offset = 0,
}) => {
  let params = `?Offset=${offset}&Limit=${limit}`;

  return getApiService(
    WEB_REQUEST_TYPE.GET,
    `${POLICY_BASE_URL}${CONFIGURED_ACCOUNTS?.replace("{policyId}", policyId)}${params}`
  );
};

export const fetchConfigurationIssues = ({
  policyId,
  limit = 20,
  offset = 0,
}) => {
  let params = `?Offset=${offset}&Limit=${limit}`;

  return getApiService(
    WEB_REQUEST_TYPE.GET,
    `${POLICY_BASE_URL}${CONFIGURATION_ISSUES?.replace("{policyId}", policyId)}${params}`
  );
};
